import axios, {AxiosResponse} from "axios";
import {PlanMenuWeekView, Response} from "./models";
import moment from "moment/moment";

const PlanMenuBaseUrl = `${process.env.REACT_APP_API_URL}/PlanMenu`;

export const getPlanWeekView = async (date: Date) => {
    try {
        const dateOnly = moment(date).format("YYYY-MM-DD");
        const response = await axios.get<any, AxiosResponse<Response<PlanMenuWeekView>>>(
            `${PlanMenuBaseUrl}/GetPlanWeekView`,
            {
                params: {
                    planDate: dateOnly
                }
            });

        return response.data.result;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getRecipeMenuPortions = async (recipeId: number) => {
    try {
        return (await axios.get<Response<number | null>>(`${PlanMenuBaseUrl}/Plan/GetRecipePortions?recipeId=${recipeId}`)).data.result;
    } catch (error) {
        console.error(error);
        throw error;
    }
};