import axios from "axios";
import {RecipeView, Response} from "./models";

const CatalogueBaseUrl = `${process.env.REACT_APP_API_URL}/Catalogue`;

export const getRecipeView = async (recipeId: number, portions: number = -1) => {
    try {
        return (await axios.get<Response<RecipeView>>(`${CatalogueBaseUrl}/Recipes/${recipeId}?portions=${portions}`)).data.result;
    } catch (error) {
        console.error(error);
        throw error;
    }
};