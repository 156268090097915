import {motion} from "framer-motion";
import React from "react";

const animationConfiguration = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0, transition: {duration: 0}},
};

const DefaultTransition = ({key, children}: { key: string, children: React.ReactNode }) => {
    return (
        <motion.div
            key={key}
            variants={animationConfiguration}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{duration: .4}}
        >
            {children}
        </motion.div>
    );
};

export default DefaultTransition;