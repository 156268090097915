import {Box, Stack} from "@mui/material";
import './index.css';
import React from "react";

export interface BrandLogoProps {
    multiColor?: boolean;
    withImage?: boolean;
}

const BrandLogo = ({multiColor = false, withImage = true}: BrandLogoProps) => {
    return (
        <Stack direction="row" alignItems="center" spacing={1} href="https://my.cookhaus.guru" component="a"
               className="brandLogo">
            <Box component="img"
                 src="/assets/app-icon.png"
                 sx={{
                     borderRadius: 4,
                     width: '32px',
                     height: 'auto'
                 }}
            />

            <span>
                Cook
                <span className={multiColor ? 'brandLogo-part2' : ''}>
                    Haus
                </span>
            </span>
        </Stack>
    );
};

export default BrandLogo;