import {AccountStore} from "./accountStore";

export class RootStore {
    accountStore: AccountStore;

    constructor() {
        this.accountStore = new AccountStore(this);
    }
}

export const rootStore = new RootStore();