import {PlanMenuWeekView} from "../../api/models";
import {PlanDayViewProps} from "./index";
import {addDaysToDate, getEndOfWeek, getDayOfMonth, getStartOfWeek, getWeekDayName} from "../../helpers/dates";

export const mapToPlanDayViewProps = (apiModel: PlanMenuWeekView | null, weekDate: Date): PlanDayViewProps[] => {
    const result: PlanDayViewProps[] = [];
    const startOfWeek = getStartOfWeek(weekDate);
    const endOfWeek = getEndOfWeek(weekDate);
    for (let i = startOfWeek; i <= endOfWeek; i = addDaysToDate(i, 1)) {
        const dayView: PlanDayViewProps = {
            dayName: `${getWeekDayName(i)}, ${getDayOfMonth(i)}`,
        };

        const planDay = apiModel?.days.find(d => new Date(d.date).getDate() === i.getDate());
        if (planDay) {
            dayView.daySummary = planDay.daySummary;
            dayView.recipes = planDay.recipes.map(r => ({
                id: r.recipeId,
                name: r.name,
                mealType: mapMealType(r.mealType),
                previewUrl: r.preview,
                estimatedMinutes: r.estimatedMinutes
            })).sort((a, b) => compareMealTypes(a.mealType, b.mealType));
        }

        result.push(dayView);
    }

    return result;
};

const mapMealType = (mealType: string): MealTypeRu => {
    switch (mealType) {
        case 'Breakfast':
            return 'Завтрак';
        case 'Lunch':
            return 'Обед';
        case 'Dinner':
            return 'Ужин';
        default:
            return '-';
    }
};

type MealTypeRu = 'Завтрак' | 'Обед' | 'Ужин' | '-';
const mealTypesToNumber = {
    'Завтрак': 0,
    'Обед': 1,
    'Ужин': 2,
    '-': 3
};

const compareMealTypes = (a: MealTypeRu, b: MealTypeRu): number => {
    return mealTypesToNumber[a] - mealTypesToNumber[b]
}