import {Box, Button, Container, InputAdornment, Paper, Snackbar, Stack, TextField, Typography} from "@mui/material";
import {EmailOutlined, EmailRounded} from "@mui/icons-material";
import Footer from "../../components/footer";
import DefaultTransition from "../../animations/defaultTransition";
import React, {memo, useState} from "react";
import {sendSignInCode, signIn} from "../../api/account";
import {ErrorCodes, hasError} from "../../api/models";
import Countdown from "react-countdown";
import './index.css';
import ReactCodeInput from "react-code-input";
import {useRootStore} from "../../store/provider";
import {useNavigate} from "react-router-dom";

interface ResendButtonProps {
    email: string
}

const ResendButton = memo((props: ResendButtonProps) => {
    const [canResend, setCanResend] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    const handleResend = async () => {
        setSnackOpen(true);
        setCanResend(false);
        await sendSignInCode(props.email);
    };

    const handleCountdownComplete = () => {
        setCanResend(true);
    };

    if (canResend) {
        return (
            <Button variant="text"
                    color="primary"
                    sx={{
                        textTransform: 'none',
                        borderRadius: 4,
                        fontWeight: 'bold',
                        mt: 3
                    }}
                    size="large"
                    fullWidth
                    onClick={handleResend}>
                Отправить код повторно
            </Button>
        );
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={snackOpen}
                onClose={() => setSnackOpen(false)}
                message="Код был отправлен еще раз."
                autoHideDuration={3 * 1000}
                key={'snackTop'}
            />

            <Button variant="text"
                    color="primary"
                    sx={{
                        textTransform: 'none',
                        borderRadius: 4,
                        fontWeight: 'bold',
                        mt: 3
                    }}
                    size="large"
                    disabled={true}
                    fullWidth>
                Отправить код повторно через

                <Countdown date={new Date(Date.now() + 60 * 1000)}
                           renderer={({hours, minutes, seconds}) => {
                               if (minutes > 0) {
                                   return (<span className="countDownTimer">{minutes}:{seconds}</span>);
                               }

                               return (<span className="countDownTimer">{seconds} сек.</span>);
                           }}
                           onComplete={handleCountdownComplete}/>
            </Button>
        </>
    );
});

interface EnterCodeFormProps {
    email: string
}

const EnterCodeForm = (props: EnterCodeFormProps) => {
    const rootStore = useRootStore();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isInvalidCode, setIsInvalidCode] = useState(false);

    const handleCodeChange = async (code: string) => {
        setIsInvalidCode(false);
        if (code.length < 4) {
            return
        }

        setIsLoading(true);
        try {
            const signInResult = await signIn(props.email, code);
            if (!signInResult.success) {
                setIsInvalidCode(true);
                return;
            }

            setIsInvalidCode(false);
            rootStore.accountStore.setAccessToken(signInResult.result!.accessToken);
            navigate('/');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <DefaultTransition key="enterCodeForm">
            <Stack direction="column" alignItems="center" width="100%">
                <EmailOutlined
                    color="secondary"
                    sx={{
                        borderRadius: 4,
                        width: '72px',
                        height: 'auto'
                    }}
                />

                <Typography variant="body2" mt={1} textAlign="center" fontSize="18px">
                    Отправили письмо с кодом для входа на вашу почту <b>{props.email}</b>. Если письма с кодом нет
                    в папке "Входящие",
                    пожалуйста, проверьте папку "Спам".
                </Typography>

                <Box mt={4}/>
                <ReactCodeInput name="code"
                                inputMode="numeric"
                                isValid={!isInvalidCode}
                                disabled={isLoading}
                                fields={4}
                                onChange={handleCodeChange}/>

                <ResendButton email={props.email}/>
            </Stack>
        </DefaultTransition>
    );
};

interface EmailFormProps {
    onEmailEntered: (email: string) => void;
}

const EmailForm = (props: EmailFormProps) => {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailEntered = async (email: string) => {
        setIsLoading(true);

        try {
            const signInCodeResult = await sendSignInCode(email);
            if (!signInCodeResult.success) {
                if (hasError(signInCodeResult, ErrorCodes.authCode.accountNotFound)) {
                    setError("Такой аккаунт еще не зарегистрирован.");
                    return;
                }

                setError("Произошла неизвестная ошибка.");
                return;
            }

            setError("");
            props.onEmailEntered(email);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <DefaultTransition key="emailForm">
            <Stack direction="column"
                   alignItems="center"
                   width="100%"
                   component="form"
                   onSubmit={async (event) => {
                       event.preventDefault();
                       const formData = new FormData(event.currentTarget);
                       const formJson = Object.fromEntries(formData.entries());
                       const email = formJson.email;
                       await handleEmailEntered(email as string);
                   }}>

                <Box component="img"
                     src="/assets/app-icon.png"
                     sx={{
                         borderRadius: 4,
                         width: '72px',
                         height: 'auto'
                     }}
                />

                <Typography mt={1} variant="body1" fontWeight="bold" fontSize="24px">
                    Войти в CookHaus
                </Typography>

                <Box mt={3}/>
                <TextField
                    autoFocus={true}
                    required
                    margin="dense"
                    id="name"
                    placeholder="Введите ваш email"
                    name="email"
                    disabled={isLoading}
                    error={error.length > 0}
                    helperText={error}
                    type="email"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">
                                <EmailRounded/>
                            </InputAdornment>
                        )
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 4,
                        }
                    }}
                />

                <Button variant="contained"
                        color="primary"
                        sx={{
                            color: 'white',
                            textTransform: 'none',
                            borderRadius: 4,
                            mt: 2
                        }}
                        size="large"
                        fullWidth
                        disabled={isLoading}
                        type="submit">
                    Далее
                </Button>
                <Button variant="text"
                        color="secondary"
                        sx={{
                            textTransform: 'none',
                            borderRadius: 4,
                            fontWeight: 'bold',
                            mt: 1
                        }}
                        component="a"
                        href="https://quiz.cookhaus.guru"
                        target="_blank"
                        size="large"
                        fullWidth
                        type="submit">
                    Еще нет аккаунта?
                </Button>
            </Stack>
        </DefaultTransition>
    );
}

const LoginPage = () => {
    const [email, setEmail] = useState('');

    return (
        <DefaultTransition key="loginPage">
            <Container maxWidth="xs">
                <Stack direction="column"
                       height="100vh"
                       alignItems="center">
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <Paper elevation={2}
                               sx={{
                                   width: '100%',
                                   borderRadius: 3,
                                   p: 3
                               }}>
                            {email.length === 0 && <EmailForm onEmailEntered={(email) => setEmail(email)}/>}
                            {email.length !== 0 && <EnterCodeForm email={email}/>}
                        </Paper>
                    </Box>

                    <Footer/>
                    <Box mt={1}/>
                </Stack>
            </Container>
        </DefaultTransition>
    );
};

export default LoginPage;