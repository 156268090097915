import AppContainer from "../../components/appContainer";
import {Container, Paper, Stack, Typography} from "@mui/material";
import {ArrowRightAltSharp} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useCallback, useState} from "react";
import LogoutDialog from "../../components/logoutDialog";

const MenuItem = ({name, href, onClick}: { name: string, href: string | undefined, onClick?: () => void }) => {
    return (
        <Paper elevation={3}
               component={href ? Link : 'a'}
               to={href}
               onClick={onClick}
               sx={{
                   p: 2,
                   borderRadius: 3
               }}>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" fontSize="22px">
                    {name}
                </Typography>

                <ArrowRightAltSharp fontSize="large"/>
            </Stack>
        </Paper>
    );
};

const InfoPage = () => {
    const [logoutOpen, setLogoutOpen] = useState(false);

    const handleOpenLogout = useCallback(() => {
        setLogoutOpen(true);
    }, []);

    const handleDismissRequest = useCallback(() => {
        setLogoutOpen(false);
    }, []);

    return (
        <AppContainer>
            <LogoutDialog open={logoutOpen} onDismissRequest={handleDismissRequest}/>
            <Container maxWidth="sm">
                <Stack direction="column" mt={5} spacing={2} sx={{
                    '& a': {
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }
                }}>
                    <MenuItem name="Политика конфиденциальности" href="https://cookhaus.guru/privacy_policy"/>
                    <MenuItem name="Условия использования" href="https://cookhaus.guru/terms_of_use"/>
                    <MenuItem name="Выйти" href={undefined} onClick={handleOpenLogout}/>
                </Stack>
            </Container>
        </AppContainer>
    );
};

export default InfoPage;