import React from "react";
import {RootStore} from "./rootStore";

const StoreContext = React.createContext<RootStore | undefined>(undefined);

export const StoreProvider = ({children, store}: { children: React.ReactNode, store: RootStore }) => {
    return (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
};

export const useRootStore = () => React.useContext(StoreContext)!;
