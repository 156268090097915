import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {Fragment} from "react";
import {useRootStore} from "../../store/provider";
import {observer} from "mobx-react-lite";

const LogoutDialog = observer(({open, onDismissRequest}: { open: boolean, onDismissRequest: () => void }) => {
    const {accountStore} = useRootStore();

    const handleLogout = () => {
        accountStore.clearAccessToken();
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onDismissRequest}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Выйти из аккаунта?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={onDismissRequest}>Отмена</Button>
                    <Button onClick={handleLogout} autoFocus>
                        Да, выйти
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
});

export default LogoutDialog;