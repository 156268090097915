import {observer} from "mobx-react-lite";
import React, {useEffect, useMemo, useState} from "react";
import AppContainer from "../../components/appContainer";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Box, Grow, MenuItem, Paper, Select, Skeleton, Stack, Typography} from "@mui/material";
import {addDaysToDate, formatMinutes, getEndOfWeek, getStartOfNextWeek, getStartOfWeek} from "../../helpers/dates";
import KcalCircle from "../../components/kcalCircle";
import './index.css';
import {Link} from "react-router-dom";
import {getPlanWeekView} from "../../api/planMenu";
import {DaySummary} from "../../api/models";
import {mapToPlanDayViewProps} from "./mappers";

const getWeekVariants = () => {
    const currentTime = new Date();
    const today = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
    const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14);
    const end = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
    const variants = [];

    const dateFormatOptions: Intl.DateTimeFormatOptions = {month: "2-digit", day: "2-digit"};
    for (let i = start; i < end; i = getStartOfNextWeek(i)) {
        const startWeek = getStartOfWeek(i);
        const endWeek = getEndOfWeek(i);

        variants.push({
            name: `Неделя ${startWeek.toLocaleDateString('ru-RU', dateFormatOptions)} - ${endWeek.toLocaleDateString('ru-RU', dateFormatOptions)}`,
            value: startWeek.toISOString(),
            isCurrent: getStartOfWeek(today).getTime() === startWeek.getTime()
        });
    }

    return variants;
};

export interface RecipeCardProps {
    id: number;
    name: string;
    mealType: string;
    previewUrl: string;
    estimatedMinutes: number;
}

const RecipeCard = ({id, name, mealType, estimatedMinutes, previewUrl}: RecipeCardProps) => {
    return (
        <Paper elevation={0}
               component={Link}
               to={`/recipe/${id}`}
               sx={{
                   borderRadius: 3,
                   textDecoration: 'none',
                   border: '#e0e8f6 1px solid',
                   p: 1
               }}>
            <Stack direction="row" spacing={1} pr={1}>
                <Box component="img"
                     src={previewUrl}
                     width="150px"
                     height="auto"
                     sx={{
                         borderRadius: 3
                     }}/>

                <Stack direction="column" sx={{flex: 1}} spacing={1} pt={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                        <Typography variant="body1" fontSize="14px">
                            {mealType} • {formatMinutes(estimatedMinutes)}
                        </Typography>
                    </Stack>

                    <Typography variant="body1" fontWeight="bold" fontSize="18px">
                        {name}
                    </Typography>
                </Stack>
            </Stack>
        </Paper>
    );
};

export interface PlanDayViewProps {
    dayName: string;
    daySummary?: DaySummary | undefined;
    recipes?: RecipeCardProps[] | undefined;
}

const PlanDayView = ({dayName, daySummary, recipes}: PlanDayViewProps) => {
    const isKcalAvailable = daySummary !== undefined;
    const isRecipesAvailable = recipes !== undefined;

    return (
        <Stack direction="column" alignItems="left">
            <Typography variant="h5"
                        fontSize="20px"
                        fontWeight="bold"
                        textAlign="left"
                        sx={{
                            '&:first-letter': {
                                textTransform: 'uppercase'
                            }
                        }}>
                {dayName}
            </Typography>

            {isKcalAvailable && <Stack direction="row" mt={2} alignItems="center">
                <KcalCircle proteins={daySummary.proteins}
                            fats={daySummary.fats}
                            carbs={daySummary.carbohydrates}/>

                <Typography variant="body1" fontSize="24px" ml={2} color="primary">
                    {(+daySummary.kcal).toLocaleString()} кКал
                </Typography>
            </Stack>}

            {isRecipesAvailable && <>
                <Box mt={3}/>
                <Stack spacing={3} direction="column">
                    {recipes.map(r => (
                        <RecipeCard key={r.id}
                                    id={r.id}
                                    name={r.name}
                                    mealType={r.mealType}
                                    estimatedMinutes={r.estimatedMinutes}
                                    previewUrl={r.previewUrl}/>
                    ))}
                </Stack>
            </>}

            {!isRecipesAvailable && <>
                <Box mt={3}/>
                <Typography variant="body1" fontSize="16px" textAlign="center">
                    На этот день меню еще не создано :(
                </Typography>
                <Box mt={2}/>
            </>}
        </Stack>
    );
};

const MenuViewPage = observer(() => {
    const weekVariants = useMemo(() => getWeekVariants(), []);
    const [selectedWeek, setSelectedWeek] =
        useState(weekVariants.find(v => v.isCurrent)!.value);
    const [dayViews, setDayViews] = useState<PlanDayViewProps[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const selectedWeekDate = new Date(selectedWeek);
                const weekView = await getPlanWeekView(selectedWeekDate);
                setDayViews(mapToPlanDayViewProps(weekView, selectedWeekDate));
            } finally {
                setIsLoading(false);
            }
        })();
    }, [selectedWeek]);

    return (
        <AppContainer>
            <Stack direction="column" alignItems="center" mt={2}>
                <Select value={selectedWeek}
                        variant="outlined"
                        disabled={isLoading}
                        onChange={(e) => setSelectedWeek(e.target.value)}
                        sx={{
                            borderRadius: 4
                        }}>
                    {weekVariants.map((v, index) => (
                        <MenuItem key={index} value={v.value}>{v.name}</MenuItem>
                    ))}
                </Select>

                <Grow in={isLoading}>
                    <Box className="dots-flashing" mt={2} component="div"/>
                </Grow>

                {(dayViews.length > 0 || !isLoading) && <Grid2 container
                                                               mt={2}
                                                               width="100%"
                                                               columnSpacing={{
                                                                   sm: 2,
                                                                   md: 4,
                                                                   lg: 8
                                                               }}
                                                               rowSpacing={3}
                                                               columns={{
                                                                   xs: 1,
                                                                   sm: 2
                                                               }}>

                    {dayViews.map((dv, index) => (
                        <Grid2 key={index} xs={1} display="flex" justifyContent="left" alignItems="left">
                            <PlanDayView {...dv}/>
                        </Grid2>
                    ))}
                </Grid2>}
            </Stack>
        </AppContainer>
    );
});

export default MenuViewPage;