import React, {memo, useMemo} from "react";
import {Box} from "@mui/material";
import {Cell, Pie, Tooltip, PieChart} from "recharts";
import {TooltipProps} from "recharts/types/component/Tooltip";
import {NameType, ValueType} from "recharts/types/component/DefaultTooltipContent";

export interface KcalCircleProps {
    proteins: number;
    fats: number;
    carbs: number;
}

const CustomTooltip = <TValue extends ValueType, TName extends NameType>({
                                                                             active,
                                                                             payload,
                                                                             label
                                                                         }: TooltipProps<TValue, TName>) => {
    if (active && payload && payload.length) {
        const {name, value} = payload[0];
        return (
            <div className="custom-tooltip"
                 style={{backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc', marginLeft: '50px'}}>
                <p>{`${name} ${value} гр`}</p>
            </div>
        );
    }

    return null;
};

const KcalCircle = memo(({proteins, fats, carbs}: KcalCircleProps) => {
    const dataSlice = useMemo(() => [
        {name: "Белки", value: proteins},
        {name: "Жиры", value: fats},
        {name: "Углеводы", value: carbs},
    ], [proteins, fats, carbs]);

    return (
        <Box>
            <PieChart width={80} height={80}>
                <Pie data={dataSlice}
                     dataKey="value"
                     cx="50%"
                     cy="50%"
                     innerRadius={30}
                     outerRadius={40}
                     fill="#82ca9d"
                     paddingAngle={10}>
                    <Cell key={`cell-0`} fill="#F8E71C"/>
                    <Cell key={`cell-1`} fill="#BD10E0"/>
                    <Cell key={`cell-2`} fill="#FF0080"/>
                </Pie>
                <Tooltip content={CustomTooltip}/>
            </PieChart>
        </Box>
    );
});

export default KcalCircle;