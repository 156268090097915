import axios from "axios";
import {GroceryList, GroceryListView, Response} from "./models";

const GroceryListUrl = `${process.env.REACT_APP_API_URL}/GroceryList`;

export const getGroceryLists = async () => {
    try {
        return (await axios.get<Response<GroceryList[]>>(`${GroceryListUrl}/GetAccountLists?page=1&pageSize=100`)).data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getGroceryList = async (groceryListId: number) => {
    try {
        return (await axios.get<Response<GroceryListView>>(`${GroceryListUrl}/GetListView?listId=${groceryListId}`)).data.result;
    } catch (error) {
        console.error(error);
        throw error;
    }
};