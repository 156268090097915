import {BottomNavigation, BottomNavigationAction, Box, Container, Paper, Stack} from "@mui/material";
import {DeviceType, useDeviceType} from "../../helpers/hooks";
import Footer from "../footer";
import React, {memo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import BrandLogo from "../brandLogo";
import {CalendarMonthTwoTone, InfoTwoTone, LogoutTwoTone, ShoppingCartTwoTone} from "@mui/icons-material";
import './index.css';
import LogoutDialog from "../logoutDialog";

interface NavBarItemProps {
    name: string;
    href?: string | undefined;
    isActive: boolean;
    icon: React.ReactElement;
    onClick?: () => void;
}

const NavBarItem = ({name, href, icon, onClick}: NavBarItemProps) => {
    const location = useLocation();
    const isActive = location.pathname === href;

    return (
        <Stack direction="row"
               component={href ? Link : Box}
               className={isActive ? 'active link' : 'link'}
               to={href || ''}
               alignItems="center"
               onClick={onClick}
               spacing={0.5}>
            {icon}
            <div>{name}</div>
        </Stack>
    );
};

interface NavBarProps {
    showOnlyLogo: boolean;
}

const NavBar = ({showOnlyLogo}: NavBarProps) => {
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

    const handleDismissDialog = () => {
        setOpenLogoutDialog(false);
    };

    const handleLogoutClick = () => {
        setOpenLogoutDialog(true);
    };

    return (
        <>
            <LogoutDialog open={openLogoutDialog} onDismissRequest={handleDismissDialog}/>
            <Box className="NavBar"
                 sx={{
                     backgroundColor: {
                         xs: 'transparent',
                         sm: '#70B9BE'
                     },
                     borderRadius: '10px',
                     '& a': {
                         color: {
                             xs: "#000",
                             sm: "#fff"
                         },
                     }
                 }}>
                <Stack direction="row"
                       alignItems="center"
                       pl={1}
                       justifyContent='space-between'>
                    <BrandLogo/>

                    {!showOnlyLogo &&
                        <Stack direction="row" alignItems="center" spacing={3} pt={2} pb={2} pl={3} pr={3}>
                            <NavBarItem name="Обзор меню"
                                        href="/"
                                        isActive={false}
                                        icon={<CalendarMonthTwoTone/>}/>
                            <NavBarItem name="Список покупок"
                                        href="/grocery-list"
                                        isActive={false}
                                        icon={<ShoppingCartTwoTone/>}/>
                            <NavBarItem name="Выйти"
                                        isActive={false}
                                        icon={<LogoutTwoTone/>}
                                        onClick={handleLogoutClick}/>
                        </Stack>}
                </Stack>
            </Box>
        </>
    );
};

const bottomRoutes = [
    {
        label: 'Обзор меню',
        icon: (<CalendarMonthTwoTone/>),
        href: '/'
    },
    {
        label: 'Список покупок',
        icon: (<ShoppingCartTwoTone/>),
        href: '/grocery-list'
    },
    {
        label: 'Прочее',
        icon: (<InfoTwoTone/>),
        href: '/info'
    },
];

const BottomNavBar = memo(() => {
    const location = useLocation();

    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
            <BottomNavigation
                showLabels
                value={bottomRoutes.findIndex(r => r.href === location.pathname)}>
                {bottomRoutes.map((item, index) => (
                    <BottomNavigationAction key={index} label={item.label} icon={item.icon} component={Link}
                                            to={item.href}/>
                ))}
            </BottomNavigation>
        </Paper>
    );
});

export interface AppContainerProps {
    children?: React.ReactNode;
}

const AppContainer = ({children}: AppContainerProps) => {
    const deviceType = useDeviceType();

    return (
        <Container maxWidth="lg"
                   sx={{
                       p: 0
                   }}>
            <Stack direction="column" height="100vh">
                <Paper elevation={0}
                       sx={{
                           flex: 1,
                           overflowY: 'auto',
                           borderRadius: 0
                       }}>
                    <NavBar showOnlyLogo={deviceType === DeviceType.Mobile}/>

                    <Box pl={2} pr={2}>
                        {children}
                    </Box>

                    {
                        deviceType === DeviceType.Mobile && <>
                            <Box mt={10}/>
                            <BottomNavBar/>
                        </>
                    }

                </Paper>

                {
                    deviceType === DeviceType.Desktop &&
                    <>
                        <Box mt={2}/>
                        <Footer/>
                        <Box mt={1}/>
                    </>
                }
            </Stack>
        </Container>
    );
};

export default AppContainer;