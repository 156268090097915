import {makeAutoObservable} from "mobx";
import {RootStore} from "./rootStore";

const localStorageAccessTokenKey = 'cookhaus_accessToken';

export class AccountStore {
    accessToken: string | null = null;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)

        this.rootStore = rootStore;
        this.accessToken = localStorage.getItem(localStorageAccessTokenKey);
    }

    setAccessToken(accessToken: string) {
        this.accessToken = accessToken;
        localStorage.setItem(localStorageAccessTokenKey, accessToken);
    }

    clearAccessToken() {
        this.accessToken = null;
        localStorage.removeItem(localStorageAccessTokenKey);
    }
}