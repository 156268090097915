import React, {memo, useCallback, useEffect, useState} from "react";
import {GroceryList, GroceryListView} from "../../api/models";
import {getGroceryList, getGroceryLists} from "../../api/groceryList";
import {Box, Container, Divider, MenuItem, Paper, Select, Stack, Typography} from "@mui/material";
import AppContainer from "../../components/appContainer";
import plural from "plural-ru";
import {useQuery} from "react-query";
import Grid2 from "@mui/material/Unstable_Grid2";

interface GroceryListContentProps {
    listView: GroceryListView;
}

const GroceryListContent = ({listView}: GroceryListContentProps) => {
    return (
        <Container maxWidth="sm">
            <Grid2 container
                   columns={{
                       xs: 1,
                       sm: 2
                   }}
                   spacing={2}>
                {listView.items.map((item, index) => (
                    <Grid2 key={index} xs={1}>
                        <Stack direction="column" spacing={0.5} textAlign="left">
                            <Typography variant="body1" fontSize="16px" fontWeight="bold">
                                {item.name}
                            </Typography>

                            <Typography variant="body1" fontSize="16px" className="gray-2">
                                {item.quantityWithUnit}
                                {item.grams && <span>, ~{Math.round(item.grams)} гр.</span>}
                            </Typography>
                            <Divider/>
                        </Stack>
                    </Grid2>
                ))}
            </Grid2>
        </Container>
    );
};

interface GroceryListSelectProps {
    selected: GroceryList | undefined;
    onReselect: (list: GroceryList) => void;
}

const GroceryListSelect = memo(({selected, onReselect}: GroceryListSelectProps) => {
    const lists = useQuery("groceryLists", async () => {
        return (await getGroceryLists()).result || [];
    }).data;

    useEffect(() => {
        if (lists) {
            onReselect(lists[0]);
        }
    }, [lists, onReselect]);

    return (
        <>
            {!selected &&
                <Stack width="100%" alignItems="center"><Box component="div" className="dots-flashing"/></Stack>}

            {selected &&
                <Select value={selected?.id}
                        variant="outlined"
                        disabled={lists?.length === 0}
                        onChange={(e) => onReselect(lists?.find(l => l.id === Number(e.target.value))!)}
                        sx={{
                            borderRadius: 4
                        }}>
                    {lists && lists.map((v, index) => (
                        <MenuItem key={index} value={v.id}>
                            <Stack direction="column">
                                <Typography variant="body1" fontSize="20px">
                                    {v.name}
                                </Typography>

                                <Typography variant="body1" fontSize="18px" className="gray-2">
                                    {plural(v.itemsCount, "%d продукт", "%d продукта", "%d продуктов")}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    ))}
                </Select>
            }
        </>
    );
});

const GroceryListPage = () => {
    const [selectedList, setSelectedList] = useState<GroceryList | undefined>(undefined);
    const {data: listView, isLoading: listViewLoading} = useQuery(`groceryList-${selectedList?.id}`, async () => {
        if (!selectedList) {
            return undefined;
        }

        return await getGroceryList(selectedList.id);
    });

    const handleReselect = useCallback((list: GroceryList) => {
        setSelectedList(list);
    }, []);

    return (
        <AppContainer>
            <Box mt={3}/>
            <GroceryListSelect selected={selectedList} onReselect={handleReselect}/>
            <Box mt={3}/>

            {listViewLoading &&
                <Stack width="100%" alignItems="center">
                    <Box component="div" className="dots-flashing"/>
                </Stack>
            }

            {!listViewLoading && listView &&
                <GroceryListContent listView={listView}/>
            }
        </AppContainer>
    );
};

export default GroceryListPage;