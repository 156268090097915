import axios from "axios";
import {ErrorCodes, hasError, Response} from "./models";

export const configureAuthAxios = (accessToken: string) => {
    axios.defaults.headers.common['X-Web-My'] = '1';
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    axios.defaults.timeout = 60_000;
};

export const configureUnauthHandler = (onAuthorizationExpired: () => void) => {
    axios.interceptors.response.use(
        (resp) => {
            if (resp.data) {
                const genericResponse = resp.data as Response<any>;
                if (genericResponse && hasError(genericResponse, ErrorCodes.generic.unAuthorized)) {
                    onAuthorizationExpired();
                }
            }

            return resp;
        }
    )
};