import {ThemeOptions} from "@mui/material";

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#042628',
        },
        secondary: {
            main: '#70b9be',
        },
    },
};
