import {Stack, Typography} from "@mui/material";

const Footer = () => {
    return (
        <Stack direction="column"
               spacing={1}
               sx={{
                   '& a': {
                       textDecoration: "none"
                   },
                   '& a:hover': {
                       textDecoration: "underline"
                   }
               }}>
            <Stack direction="row"
                   alignItems="center"
                   justifyContent="space-between"
                   width="100%"
                   spacing={1}>
                <Typography variant="body2"
                            color="primary"
                            fontSize="14px"
                            href="https://cookhaus.guru/privacy_policy"
                            component="a">
                    Политика конфиденциальности
                </Typography>

                <Typography variant="body2"
                            color="primary"
                            fontSize="14px"
                            href="mailto:support@cookhaus.guru"
                            component="a">
                    support@cookhaus.guru
                </Typography>

                <Typography variant="body2"
                            color="primary"
                            fontSize="14px"
                            href="https://cookhaus.guru/terms_of_use"
                            component="a">
                    Правила использования
                </Typography>
            </Stack>
        </Stack>
    );
};

export default Footer;