import {useMediaQuery, useTheme} from "@mui/material";

export enum DeviceType {
    Mobile,
    Desktop
}

export function useDeviceType() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("sm"))
        ? DeviceType.Mobile
        : DeviceType.Desktop;
}