import {Link, useNavigate, useParams} from "react-router-dom";
import AppContainer from "../../components/appContainer";
import {Box, Button, Container, Paper, Stack, SvgIcon, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {RecipeView, RecipeViewIngredientCategory} from "../../api/models";
import {getRecipeView} from "../../api/catalogue";
import {
    ArrowBackIosSharp,
    ArrowOutwardOutlined,
    InfoOutlined,
    StarOutlined,
    TimerOutlined,
    TimerRounded
} from "@mui/icons-material";
import {formatMinutes} from "../../helpers/dates";
import DefaultTransition from "../../animations/defaultTransition";
import {AnimatePresence} from "framer-motion";
import Grid2 from "@mui/material/Unstable_Grid2";
import {getRecipeMenuPortions} from "../../api/planMenu";
import plural from 'plural-ru';

const IngredientCategory = ({ingredientCategory}: { ingredientCategory: RecipeViewIngredientCategory }) => {
    return (
        <Stack direction="column" spacing={1.5}>
            {ingredientCategory.hasName &&
                <Typography variant="body2" fontSize="16px" fontWeight="bold" textAlign="left">
                    {ingredientCategory.name}
                </Typography>
            }

            {ingredientCategory.ingredients.map((ingredient, index) => (
                <Stack key={index} direction="column" spacing={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1" fontSize="16px" textAlign="left">
                            {ingredient.name}
                        </Typography>

                        <Typography variant="body1" fontSize="16px" textAlign="left" className="gray-2">
                            {ingredient.quantity}
                        </Typography>
                    </Stack>

                    {ingredient.note && <Typography variant="body1" fontSize="14px" textAlign="left" className="gray-2">
                        {ingredient.note}
                    </Typography>}
                </Stack>
            ))}
        </Stack>
    );
};

const NutrientInfo = ({iconName, val}: { iconName: string, val: string }) => {
    return (
        <Stack direction="row" alignItems="center" spacing={1.5}>
            <Box component="img"
                 src={`/assets/${iconName}`}
                 width="40px"/>

            <Typography variant="body1" fontSize="16px">
                {val}
            </Typography>
        </Stack>
    );
};

const RecipeContent = ({recipe}: { recipe: RecipeView }) => {
    const navigate = useNavigate();

    return (
        <Stack direction="column">
            <Button variant="outlined"
                    color="primary"
                    onClick={() => navigate('/')}>
                <ArrowBackIosSharp/>
                Назад
            </Button>

            <Box component="img"
                 mt={2}
                 src={recipe.preview}
                 sx={{
                     borderRadius: 0
                 }}
            />

            <Typography variant="h1" fontSize="24px" fontWeight="bold" mt={3} textAlign="left">
                {recipe.name}
            </Typography>

            <Stack direction="row" mt={1} spacing={2}>
                <Stack direction="row" className="gray-2" alignItems="center" spacing={0.5}>
                    <TimerOutlined/>
                    <div>{formatMinutes(recipe.estimatedMinutes)}</div>
                </Stack>

                <Stack direction="row" className="gray-2" alignItems="center" spacing={0.5}>
                    <StarOutlined/>
                    <div>{recipe.difficulty} сложность</div>
                </Stack>
            </Stack>

            {recipe.sourceName !== null && recipe.sourceLink !== null &&
                <Typography variant="body1" fontSize="16px" textAlign="left" mt={1} color="primary">
                    Источник <a href={recipe.sourceLink} className="secondary-link">{recipe.sourceName}</a>
                </Typography>
            }

            {recipe.nutrientsByPortion !== null &&
                <Grid2 container columns={2} mt={2} spacing={2}>
                    <Grid2 xs={1}>
                        <NutrientInfo iconName="kcal.svg" val={`${recipe.nutrientsByPortion?.calories} кКал`}/>
                    </Grid2>

                    <Grid2 xs={1}>
                        <NutrientInfo iconName="proteins.svg" val={`${recipe.nutrientsByPortion?.proteins} гр белков`}/>
                    </Grid2>

                    <Grid2 xs={1}>
                        <NutrientInfo iconName="fats.svg" val={`${recipe.nutrientsByPortion?.fats} гр жиров`}/>
                    </Grid2>

                    <Grid2 xs={1}>
                        <NutrientInfo iconName="carbs.svg"
                                      val={`${recipe.nutrientsByPortion?.carbohydrates} гр углеводов`}/>
                    </Grid2>

                    <Grid2 xs={2}>
                        <Stack direction="row" alignItems="center" className="gray-2" spacing={1}>
                            <InfoOutlined/>
                            <Typography variant="body1" fontSize="14px" textAlign="left">
                                Информация о КБЖУ указана в расчете на порцию
                            </Typography>
                        </Stack>
                    </Grid2>
                </Grid2>
            }

            <Typography variant="h1" fontSize="24px" fontWeight="bold" mt={2} textAlign="left">
                Ингредиенты
            </Typography>

            <Typography variant="body1" fontSize="18px" mt={.5} textAlign="left">
                {plural(recipe.portions, "%d порция", "%d порции", "%d порций")} (по меню)
            </Typography>

            <Box mt={2}/>
            {recipe.ingredientCategories.map((ingredientCategory, index) => (
                <IngredientCategory ingredientCategory={ingredientCategory} key={index}/>
            ))}

            <Paper elevation={3}
                   sx={{
                       mt: 3,
                       borderRadius: 3,
                       p: 3
                   }}>
                <Stack direction="column" alignItems="center">
                    <Typography variant="body1" fontSize="22px" fontWeight="bold">
                        Начнем готовить?
                    </Typography>

                    <Typography variant="body2" fontSize="18px" mt={1}>
                        Чтобы посмотреть шаги приготовления, нужно перейти на страницу автора рецепта. Нажмите на кнопку
                        ниже, чтобы перейти.
                    </Typography>

                    <Box mt={2}/>
                    <Button variant="contained"
                            color="primary"
                            component="a"
                            target="_blank"
                            href={recipe.recipeLink}
                            sx={{
                                color: "white"
                            }}>
                        Перейти
                        <ArrowOutwardOutlined/>
                    </Button>
                </Stack>
            </Paper>

            {recipe.advice !== null &&
                <Paper elevation={0}
                       sx={{
                           backgroundColor: '#70B9BE',
                           color: "white",
                           mt: 3,
                           p: 2
                       }}>
                    <Stack direction="column" textAlign="left">
                        <Typography variant="body1" fontSize="18px" fontWeight="bold">
                            Полезно знать
                        </Typography>

                        <Typography variant="body1" fontSize="16px">
                            {recipe.advice}
                        </Typography>
                    </Stack>
                </Paper>
            }

            <Box mt={5}/>
        </Stack>
    );
};

const RecipePage = () => {
    const {id} = useParams();
    const [recipe, setRecipe] = useState<RecipeView | null>(null);

    useEffect(() => {
        (async () => {
            if (id) {
                const idNumber = Number(id);
                const menuPortions = await getRecipeMenuPortions(idNumber);
                setRecipe(await getRecipeView(idNumber, menuPortions || -1));
            }
        })()
    }, [id]);

    return (
        <AppContainer>
            <Container maxWidth="sm" sx={{mt: 2}}>
                <AnimatePresence>
                    {recipe === null &&
                        <DefaultTransition key="loadingRecipe">
                            <Stack width="100%" alignItems="center">
                                <Box className="dots-flashing center"/>
                            </Stack>
                        </DefaultTransition>
                    }

                    {recipe !== null &&
                        <DefaultTransition key="loadedRecipe">
                            <RecipeContent recipe={recipe}/>
                        </DefaultTransition>}
                </AnimatePresence>
            </Container>
        </AppContainer>
    )
};

export default RecipePage;