import axios, {AxiosResponse} from "axios";
import {Response, SignInResult, SimpleSignInData} from "./models";

const AccountBaseUrl = `${process.env.REACT_APP_API_URL}/Account`;

const sendSignInCode = async (email: string) => {
    try {
        const response = await axios.post<any, AxiosResponse<Response<any>>, string>(
            `${AccountBaseUrl}/SignIn/SendAuthCode`,
            email,
            {
                headers: {
                    "Content-Type": "application/json",
                }
            }
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const signIn = async (email: string, code: string) => {
    try {
        const response = await axios.post<any, AxiosResponse<Response<SignInResult>>, SimpleSignInData>(
            `${AccountBaseUrl}/SignIn`,
            {
                Code: code,
                Email: email,
                Password: "",
                SignUpData: null,
                Type: "Simple"
            }
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export {sendSignInCode, signIn};